/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/styles/main.scss"

export const onInitialClientRender = () => {
  const captchaScript = document.createElement("script")
  captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_KEY}`
  captchaScript.async = true

  document.head.appendChild(captchaScript)
}
